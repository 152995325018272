import * as React from "react"
import { Helmet } from "react-helmet"
import Buses from "../components/buses"

import Layout from "../components/layout"

export default function IndexPage() {
  return (
    <Layout>
      <section>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Control Panel</title>
        </Helmet>
        <table className="table table-dark table-responsive">
          <Buses routeName="Montrose" route="78" stops="17944,14828" />
          <Buses routeName="Clark" route="22" stops="1933,1815" />
        </table>
      </section>
    </Layout>
  )
}
