import React, {useState, useEffect} from "react"
import { StaticImage } from "gatsby-plugin-image"
  
export function Buses(props) {
    const [busList, setBusList] = useState(null);

    const fetchBuses = () => {
      let stops = props.stops;
      let route = props.route;

      let url = `https://oypzxmm576.execute-api.us-east-1.amazonaws.com/default/fetch-buses-python?route=${route}&stops=${stops}`;

      fetch(url, { mode: "cors" })
        .then(response => response.json())
        .then(data => setBusList(data["bustime-response"]["prd"]))
        .catch(e => console.log(e))
    };
    
    useEffect(() => {
        fetchBuses();
        const interval = setInterval(() => {
            fetchBuses();
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    return (<>
        <tbody className={props.routeName}>
            {busList && busList.map((item, index) => <Bus 
                key={index}
                route={props.route}
                routeName={props.routeName} 
                direction={item.rtdir}
                prediction={item.prdctdn}
                destination={item.des} />)}
        </tbody>
    </>);
}

export function Bus(props) {
    let formatPrediction = (prediction) => {
        return (isNaN(prediction) ? '' : ' min');
    }

    return (<>
        <tr className={props.routeName + ' ' + props.direction}>
            <td className="routeLabel fit">#{props.route}</td>
            <td className="fit">
                <span className="routeLabel">{props.routeName}</span><br />
                {props.direction}<br />
                to <strong>{props.destination}</strong>                
            </td>
            <td className={'fit arrow-image ' + props.direction}>
                <StaticImage
                    src="../images/arrow.png"
                    className="arrow-image"
                    width={100}
                    quality={100}
                    formats={["PNG"]}
                />
            </td>
            <td className="prediction"><strong>{props.prediction}</strong>{formatPrediction(props.prediction)}</td>
        </tr>
    </>)
}

export default Buses